import React from "react"
import { Layout } from "../components"

export default function notfound() {
  return (
    <Layout footerless={true}>
      <h1>Not found 4044</h1>
    </Layout>
  )
}
